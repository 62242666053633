import "./style.css";

const Main = () => {
  return (
    <main id="Home">
      <p>--- Hello ---</p>
      <h1>
        I'm <span>Shubham Raut....</span>
      </h1>
      <p>Front End Developer & Content Creator</p>
      <div className="indicator"></div>
    </main>
  );
};

export default Main;
