import "./style.css";

const About = () => {
  return (
    <div className="about" id="About">
      <p>
        I'm a <span> Front End Developer.</span> I have a diverse set of <br />
        skills,ranging from design, to HTML, CSS & JavaScript.{" "}
      </p>
    </div>
  );
};

export default About;
